<template>
<img
    v-if="awsImageUrl"
    :key="`img_${awsImageUrl}`"
    :class="`sg-image ${rounded || ''}`"
    :src="awsImageUrl"
    :style="thumbnail ? 'height:100px; width:90px' : null"
>
<span v-else>
    <slot />
</span>
</template>

<script>

import { mapState } from 'vuex';
import { prettyError } from '../lib/utils';

const network = require('../lib/network');

export default {
    name: 'UserAvatar',
    props: {
        avatarUser: {
            type: Object,
            required: true,
        },
        rounded: {
            type: Boolean,
            required: false,
            default: false,
        },
        thumbnail: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            awsImageUrl: null,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
    },
    watch: {
        avatarUser: {
            deep: true,
            handler() {
                this.init();
            },
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.awsImageUrl = null;
            if (this.avatarUser.awsImageUrl) {
                this.awsImageUrl = this.avatarUser.awsImageUrl;
            } else if (this.avatarUser.pictureGuid) { // student
                this.awsImageUrl = `https://lilo-app.s3.amazonaws.com/${this.avatarUser.pictureGuid}.jpg`;
            } else {
                this.getGoogleUserImage();
            }
        },
        getGoogleUserImage() {
            if (this.loading) return;
            this.loading = true;
            if ((this.avatarUser?.googleImageUrl || '').indexOf('default-user') > -1) return;

            if (this.avatarUser.googleUserId) {
                network.google.userImage(this.user, this.avatarUser.googleUserId, (err, results) => {
                    this.loading = false;
                    if (err) return prettyError(err);
                    this.awsImageUrl = results?.awsImageUrl || null;
                });
            }
        },
    },
};

</script>

<style scoped>

div.kt-widget__top {
    align-items: center;
}
</style>
