var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.awsImageUrl
    ? _c("img", {
        key: `img_${_vm.awsImageUrl}`,
        class: `sg-image ${_vm.rounded || ""}`,
        style: _vm.thumbnail ? "height:100px; width:90px" : null,
        attrs: { src: _vm.awsImageUrl },
      })
    : _c("span", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }