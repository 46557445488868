var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.title}_${_vm.key}` },
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u(
          [
            _vm.anecdotalEvent
              ? {
                  key: "title",
                  fn: function () {
                    return [
                      _vm.anecdotalEvent.categoryIcon
                        ? _c(
                            "span",
                            {
                              staticClass: "mr-3",
                              staticStyle: { "font-size": "1.6rem" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.anecdotalEvent.categoryIcon) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.anecdotalEvent.anecdotalEventCategoryTitle
                          ) +
                          " "
                      ),
                      _c("small", { staticClass: "ml-3" }, [
                        _vm._v(_vm._s(_vm.lastUpdated)),
                      ]),
                      !_vm.isStudentView
                        ? _c(
                            "span",
                            { staticClass: "point-container ml-3 pull-right" },
                            [
                              _vm.anecdotalEvent.pointValue > 0
                                ? _c(
                                    "span",
                                    { staticClass: "kt-font-success" },
                                    [
                                      _vm._v(
                                        "+" +
                                          _vm._s(
                                            _vm.abbrevNumber(
                                              _vm.anecdotalEvent.pointValue
                                            )
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.anecdotalEvent.pointValue < 0
                                ? _c(
                                    "span",
                                    { staticClass: "kt-font-danger" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.abbrevNumber(
                                              _vm.anecdotalEvent.pointValue
                                            )
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.canEdit && !_vm.isStudentView
              ? {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "btn btn-primary btn-sm btn-upper btn-bold mr-2",
                          attrs: {
                            to: {
                              name: "EditAnecdotal",
                              params: {
                                anecdotalEventId:
                                  _vm.anecdotalEvent.anecdotalEventId,
                              },
                            },
                          },
                        },
                        [_vm._v(" Edit Anecdotal ")]
                      ),
                      _vm.resolvable
                        ? [
                            !_vm.anecdotalEvent.resolved
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn btn-success btn-sm btn-upper btn-bold",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.resolveAnecdotalEvent(true)
                                      },
                                    },
                                  },
                                  [_vm._v(" Resolve ")]
                                )
                              : _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn btn-warning btn-sm btn-upper btn-bold",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.resolveAnecdotalEvent(false)
                                      },
                                    },
                                  },
                                  [_vm._v(" Unresolve ")]
                                ),
                          ]
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _vm.anecdotalEvent
        ? _c(
            "div",
            {
              class: {
                "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid":
                  !_vm.studentEnrollmentId,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "row",
                  class: { "w-100 pl-4 pr-4": !_vm.studentEnrollmentId },
                },
                [
                  _c("div", { staticClass: "col-8" }, [
                    _c("div", { staticClass: "kt-portlet" }, [
                      _c("div", { staticClass: "kt-portlet__body" }, [
                        !_vm.anecdotalEvent.anecdotalEventDescription
                          ? _c("h6", [
                              _vm._v(" No anecdotal description supplied. "),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "w-100 markdown-container" },
                          [
                            _vm.anecdotalEvent.anecdotalEventDescription
                              ? _c("VueShowdown", {
                                  staticClass: "markdown",
                                  attrs: {
                                    markdown:
                                      _vm.anecdotalEvent
                                        .anecdotalEventDescription,
                                    flavor: "github",
                                    options: { emoji: true },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    !_vm.isStudentView
                      ? _c("div", { staticClass: "kt-chat" }, [
                          _c("div", { staticClass: "kt-portlet" }, [
                            _vm._m(0),
                            _vm.loadingChats
                              ? _c(
                                  "div",
                                  { staticClass: "kt-portlet__body" },
                                  [_c("PortletLoader")],
                                  1
                                )
                              : _vm.comments.length > 0
                              ? _c("div", { staticClass: "kt-portlet__body" }, [
                                  _c(
                                    "div",
                                    { attrs: { id: "chats" } },
                                    _vm._l(_vm.comments, function (comment) {
                                      return _c(
                                        "div",
                                        {
                                          key: comment.vueId,
                                          staticClass: "kt-chat__messages",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "kt-chat__message",
                                              class: comment.fromMe
                                                ? "kt-chat__message--right"
                                                : "",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-chat__text p-3",
                                                  class: comment.fromMe
                                                    ? "kt-bg-light-brand"
                                                    : "kt-bg-light-success",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        comment.anecdotalEventComment
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-chat__user pt-2",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "kt-chat__datetime",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            comment.relativeDate
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-muted ml-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            comment.author
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  comment.user
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-media kt-media--circle kt-media--xs",
                                                        },
                                                        [
                                                          _c("UserAvatar", {
                                                            attrs: {
                                                              "avatar-user":
                                                                comment.user,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ])
                              : _vm.comments.length == 0
                              ? _c("div", { staticClass: "kt-portlet__body" }, [
                                  _c("h6", [_vm._v(" No comments yet. ")]),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "kt-portlet__body" }, [
                              _c(
                                "div",
                                { staticClass: "form-group form-group-last" },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.anecdotalEventComment,
                                        expression: "anecdotalEventComment",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      placeholder: "Add a comment here",
                                      rows: "1",
                                    },
                                    domProps: {
                                      value: _vm.anecdotalEventComment,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.anecdotalEventComment =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _c("div", { staticClass: "row w-100 pt-5" }, [
                                _c("div", { staticClass: "col-6" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-brand btn-sm btn-upper btn-bold",
                                      class: {
                                        "kt-spinner kt-spinner--sm kt-spinner--light":
                                          _vm.saving,
                                      },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.saveComment.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Add comment ")]
                                  ),
                                ]),
                                _vm._m(1),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "col-4" }, [
                    !_vm.isStudentView && _vm.isInCurrentTerm
                      ? _c("div", { staticClass: "kt-portlet" }, [
                          _c("div", { staticClass: "kt-portlet__head" }, [
                            _c(
                              "div",
                              { staticClass: "kt-portlet__head-label" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "kt-portlet__head-icon" },
                                  [
                                    _c("SVGIcon", {
                                      attrs: {
                                        name: "users",
                                        "hex-color": `#0d74cb`,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "h3",
                                  { staticClass: "kt-portlet__head-title" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.students.length) +
                                        " Student" +
                                        _vm._s(
                                          _vm.students.length > 1 ? "s" : ""
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("div", {
                              staticClass: "kt-portlet__head-toolbar",
                            }),
                          ]),
                          _c("div", { staticClass: "kt-portlet__body" }, [
                            _c(
                              "div",
                              { staticClass: "tab-content student-list" },
                              [
                                _c("div", { staticClass: "tab-pane active" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget4" },
                                    _vm._l(_vm.students, function (user, idx) {
                                      return _c(
                                        "div",
                                        {
                                          key: `user_${String(idx)}_${
                                            user ? user.studentEnrollmentId : 0
                                          }_${user ? user.schoolStaffId : 0}`,
                                          staticClass: "kt-widget4__item",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.openPanelForStudent(
                                                user
                                              )
                                            },
                                          },
                                        },
                                        [
                                          !user
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-widget4__pic kt-widget4__pic--pic",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "kt-media kt-media--info",
                                                        attrs: {
                                                          href: "#",
                                                          title:
                                                            "Unknown Participant",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("?"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-widget4__info",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "kt-widget4__username",
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Other Student "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "kt-widget4__text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " No access to student "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : [
                                                user.studentEnrollmentId
                                                  ? [
                                                      user.pictureGuid
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "kt-widget4__pic kt-widget4__pic--pic",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: `https://lilo-app.s3.amazonaws.com/${user.pictureGuid}.jpg`,
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "kt-widget4__pic kt-widget4__pic--pic",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "kt-media kt-media--info",
                                                                  attrs: {
                                                                    href: "#",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        return _vm.openPanelForStudent(
                                                                          user
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        user.lastName.substr(
                                                                          0,
                                                                          1
                                                                        )
                                                                      ) +
                                                                        _vm._s(
                                                                          user.firstName.substr(
                                                                            0,
                                                                            1
                                                                          )
                                                                        )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-widget4__info",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "kt-widget4__username",
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                            return _vm.openPanelForStudent(
                                                              user
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              user.lastName
                                                            ) +
                                                            ", " +
                                                            _vm._s(
                                                              user.firstName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    user.portfolioType ==
                                                    "Student"
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "kt-widget4__text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  user.extStudentId.substring(
                                                                    0,
                                                                    3
                                                                  )
                                                                ) +
                                                                "-" +
                                                                _vm._s(
                                                                  user.extStudentId.substring(
                                                                    3,
                                                                    6
                                                                  )
                                                                ) +
                                                                "-" +
                                                                _vm._s(
                                                                  user.extStudentId.substring(
                                                                    6,
                                                                    9
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "kt-portlet" }, [
                      _c("div", { staticClass: "kt-portlet__body" }, [
                        _c("div", { staticClass: "tab-content" }, [
                          _c("div", { staticClass: "kt-widget6" }, [
                            _c(
                              "div",
                              { staticClass: "kt-widget6__body" },
                              [
                                _c("div", { staticClass: "kt-widget6__item" }, [
                                  _c("span", [_vm._v("Created by")]),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.anecdotalEvent.author)),
                                  ]),
                                ]),
                                _c("div", { staticClass: "kt-widget6__item" }, [
                                  _c("span", [_vm._v("Anecdotal Date")]),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "kt-font-bold kt-font-info",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.anecdotalEvent.anecdotalEventDate
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "kt-widget6__item" }, [
                                  _c("span", [_vm._v("Anecdotal Time")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.anecdotalEvent.anecdotalEventTime
                                      )
                                    ),
                                  ]),
                                ]),
                                !_vm.isStudentView
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "kt-widget6__item" },
                                        [
                                          _c("span", [_vm._v("Confidential")]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.anecdotalEvent
                                                  .isConfidential
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "kt-widget6__item" },
                                        [
                                          _c("span", [
                                            _vm._v("Visible Student"),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.anecdotalEvent
                                                  .visibleStudent
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "kt-widget6__item" },
                                        [
                                          _c("span", [
                                            _vm._v("Visible Guardian"),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.anecdotalEvent
                                                  .visibleGuardian
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _c("div", { staticClass: "kt-widget6__item" }, [
                                  _c(
                                    "span",
                                    { staticStyle: { "text-align": "left" } },
                                    [
                                      _vm._v(
                                        "Edited " +
                                          _vm._s(_vm.anecdotalEvent.lastUpdated)
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("span", { staticClass: "kt-portlet__head-icon kt-font-primary" }, [
          _c("i", { staticClass: "la la-weixin" }),
        ]),
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Comments "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6 mt-3" }, [
      _c("div", { staticClass: "text-muted text-right" }, [
        _vm._v(" Comments are only visible to school staff. "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }