<template>
<div :key="`key_${title}_${key}`">
    <GeneralSubHeader>
        <template
            v-if="anecdotalEvent"
            #title
        >
            <span
                v-if="anecdotalEvent.categoryIcon"
                class="mr-3"
                style="font-size: 1.6rem;"
            >
                {{ anecdotalEvent.categoryIcon }}
            </span>

            {{ anecdotalEvent.anecdotalEventCategoryTitle }}

            <small class="ml-3">{{ lastUpdated }}</small>
            <span
                v-if="!isStudentView"
                class="point-container ml-3 pull-right"
            >
                <span
                    v-if="anecdotalEvent.pointValue > 0"
                    class="kt-font-success"
                >+{{ abbrevNumber(anecdotalEvent.pointValue) }}</span>

                <span
                    v-if="anecdotalEvent.pointValue < 0"
                    class="kt-font-danger"
                > {{ abbrevNumber(anecdotalEvent.pointValue) }}</span>
            </span>
        </template>
        <template
            v-if="canEdit && !isStudentView"
            #buttons
        >
            <router-link
                :to="{name: 'EditAnecdotal', params: { anecdotalEventId: anecdotalEvent.anecdotalEventId } }"
                class="btn btn-primary btn-sm btn-upper btn-bold mr-2"
            >
                Edit Anecdotal
            </router-link>
            <template v-if="resolvable">
                <a
                    v-if="!anecdotalEvent.resolved"
                    class="btn btn-success btn-sm btn-upper btn-bold"
                    @click.stop.prevent="resolveAnecdotalEvent(true)"
                >
                    Resolve
                </a>
                <a
                    v-else
                    class="btn btn-warning btn-sm btn-upper btn-bold"
                    @click.stop.prevent="resolveAnecdotalEvent(false)"
                >
                    Unresolve
                </a>
            </template>
        </template>
    </GeneralSubHeader>
    <div
        v-if="anecdotalEvent"
        :class="{'kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid': !studentEnrollmentId}"
    >
        <div
            :class="{'w-100 pl-4 pr-4': !studentEnrollmentId}"
            class="row"
        >
            <div class="col-8">
                <div class="kt-portlet">
                    <div class="kt-portlet__body">
                        <h6 v-if="!anecdotalEvent.anecdotalEventDescription">
                            No anecdotal description supplied.
                        </h6>
                        <div class="w-100 markdown-container">
                            <VueShowdown
                                v-if="anecdotalEvent.anecdotalEventDescription"
                                :markdown="anecdotalEvent.anecdotalEventDescription"
                                flavor="github"
                                class="markdown"
                                :options="{ emoji: true }"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="!isStudentView"
                    class="kt-chat"
                >
                    <div class="kt-portlet">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <span class="kt-portlet__head-icon kt-font-primary"><i class="la la-weixin" /></span><h3 class="kt-portlet__head-title">
                                    Comments
                                </h3>
                            </div>
                        </div>
                        <div
                            v-if="loadingChats"
                            class="kt-portlet__body"
                        >
                            <PortletLoader />
                        </div>
                        <div
                            v-else-if="comments.length > 0"
                            class="kt-portlet__body"
                        >
                            <div id="chats">
                                <div
                                    v-for="comment in comments"
                                    :key="comment.vueId"
                                    class="kt-chat__messages"
                                >
                                    <div
                                        class="kt-chat__message"
                                        :class="comment.fromMe ? 'kt-chat__message--right' : ''"
                                    >
                                        <div
                                            :class="comment.fromMe ? 'kt-bg-light-brand' : 'kt-bg-light-success'"
                                            class="kt-chat__text p-3"
                                        >
                                            {{ comment.anecdotalEventComment }}
                                        </div>
                                        <div class="kt-chat__user pt-2">
                                            <span class="kt-chat__datetime">
                                                {{ comment.relativeDate }}
                                            </span>
                                            <span class="text-muted ml-2">
                                                {{ comment.author }}
                                            </span>

                                            <span
                                                v-if="comment.user"
                                                class="kt-media kt-media--circle kt-media--xs"
                                            >
                                                <UserAvatar :avatar-user="comment.user" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-else-if="comments.length == 0"
                            class="kt-portlet__body"
                        >
                            <h6> No comments yet. </h6>
                        </div>

                        <div class="kt-portlet__body">
                            <div class="form-group form-group-last">
                                <textarea
                                    v-model="anecdotalEventComment"
                                    class="form-control"
                                    placeholder="Add a comment here"
                                    rows="1"
                                />
                            </div>
                            <div class="row w-100 pt-5">
                                <div class="col-6">
                                    <button
                                        type="button"
                                        :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                                        class="btn btn-brand btn-sm btn-upper btn-bold"
                                        @click.stop.prevent="saveComment"
                                    >
                                        Add comment
                                    </button>
                                </div>
                                <div class="col-6 mt-3">
                                    <div
                                        class="text-muted text-right"
                                    >
                                        Comments are only visible to school staff.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div
                    v-if="!isStudentView && isInCurrentTerm"
                    class="kt-portlet"
                >
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <span class="kt-portlet__head-icon">
                                <SVGIcon
                                    name="users"
                                    :hex-color="`#0d74cb`"
                                />
                            </span>
                            <h3 class="kt-portlet__head-title">
                                {{ students.length }} Student{{ students.length > 1 ? 's' : '' }}
                            </h3>
                        </div>

                        <div class="kt-portlet__head-toolbar" />
                    </div>
                    <div class="kt-portlet__body">
                        <div class="tab-content student-list">
                            <div class="tab-pane active">
                                <div class="kt-widget4">
                                    <div
                                        v-for="(user, idx) in students"
                                        :key="`user_${String(idx)}_${user ? user.studentEnrollmentId : 0}_${user ? user.schoolStaffId : 0}`"
                                        class="kt-widget4__item"
                                        @click.stop.prevent="openPanelForStudent(user)"
                                    >
                                        <template v-if="!user">
                                            <div class="kt-widget4__pic kt-widget4__pic--pic">
                                                <a
                                                    href="#"
                                                    class="kt-media kt-media--info"
                                                    title="Unknown Participant"
                                                    @click.stop.prevent=""
                                                >
                                                    <span>?</span>
                                                </a>
                                            </div>
                                            <div class="kt-widget4__info">
                                                <a
                                                    href="#"
                                                    class="kt-widget4__username"
                                                    @click.stop.prevent=""
                                                >
                                                    Other Student
                                                </a>
                                                <p class="kt-widget4__text">
                                                    No access to student
                                                </p>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <template v-if="user.studentEnrollmentId">
                                                <div
                                                    v-if="user.pictureGuid"
                                                    class="kt-widget4__pic kt-widget4__pic--pic"
                                                >
                                                    <img :src="`https://lilo-app.s3.amazonaws.com/${user.pictureGuid}.jpg`">
                                                </div>
                                                <div
                                                    v-else
                                                    class="
                                                    kt-widget4__pic
                                                    kt-widget4__pic--pic"
                                                >
                                                    <a
                                                        href="#"
                                                        class="kt-media kt-media--info"
                                                        @click.stop.prevent="openPanelForStudent(user)"
                                                    >
                                                        <span>{{ user.lastName.substr(0, 1) }}{{ user.firstName.substr(0, 1) }}</span>
                                                    </a>
                                                </div>
                                            </template>

                                            <div class="kt-widget4__info">
                                                <a
                                                    href="#"
                                                    class="kt-widget4__username"
                                                    @click.stop.prevent="openPanelForStudent(user)"
                                                >
                                                    {{ user.lastName }}, {{ user.firstName }}
                                                </a>
                                                <p
                                                    v-if="user.portfolioType =='Student'"
                                                    class="kt-widget4__text"
                                                >
                                                    {{ user.extStudentId.substring(0, 3) }}-{{
                                                        user.extStudentId.substring(3, 6)
                                                    }}-{{ user.extStudentId.substring(6, 9) }}
                                                </p>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="kt-portlet">
                    <div class="kt-portlet__body">
                        <div class="tab-content">
                            <div class="kt-widget6">
                                <div class="kt-widget6__body">
                                    <div class="kt-widget6__item">
                                        <span>Created by</span><span>{{ anecdotalEvent.author }}</span>
                                    </div>
                                    <div class="kt-widget6__item">
                                        <span>Anecdotal Date</span><span class="kt-font-bold kt-font-info">{{ anecdotalEvent.anecdotalEventDate }}</span>
                                    </div>
                                    <div class="kt-widget6__item">
                                        <span>Anecdotal Time</span><span>{{ anecdotalEvent.anecdotalEventTime }}</span>
                                    </div>
                                    <template v-if="!isStudentView">
                                        <div class="kt-widget6__item">
                                            <span>Confidential</span><span>{{ anecdotalEvent.isConfidential }}</span>
                                        </div>
                                        <div class="kt-widget6__item">
                                            <span>Visible Student</span><span>{{ anecdotalEvent.visibleStudent }}</span>
                                        </div>
                                        <div class="kt-widget6__item">
                                            <span>Visible Guardian</span><span>{{ anecdotalEvent.visibleGuardian }}</span>
                                        </div>
                                    </template>
                                    <div class="kt-widget6__item">
                                        <span style="text-align: left">Edited {{ anecdotalEvent.lastUpdated }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
// @ts-ignore
import { VueShowdown } from 'vue-showdown';

import Types from '../../store/Types';
import userMixins from '../../store/mixins/userMixins';
import panelMixins from '../../store/mixins/panelMixins';
import studentMixins from '../../store/mixins/studentMixins';
import * as network from '../../network';

import PortletLoader from '../../components/PortletLoader.vue';
import UserAvatar from '../../components/UserAvatar.vue';
import GeneralSubHeader from '../../components/GeneralSubHeader.vue';

export default {
    name: 'ViewAnecdotal',
    components: {
        VueShowdown,
        PortletLoader,
        UserAvatar,
        GeneralSubHeader,
    },
    mixins: [userMixins, panelMixins, studentMixins],
    props: {
        studentEnrollmentId: {
            type: [Number, String],
            default: null,
            required: false,
        },
    },
    data() {
        return {
            loadingChats: true,
            saving: false,
            anecdotalEventComment: null,
            anecdotalEventComments: [],
            key: 0,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            forceStudentView: (state) => state.forceStudentView,
            anecdotalEvents: (state) => state.database.anecdotalEvents,
            anecdotalEventCategories: (state) => state.database.anecdotalEventCategories,
            editSchoolAnecdotalEvents: (state) => state.user.userPermissions.editSchoolAnecdotalEvents,
            schoolStaff: (state) => state.database.schoolStaff,
        }),
        passableAnecdotalEvent() {
            let { anecdotalEventId } = this.$route.params;
            if (this.studentEnrollmentId) {
                anecdotalEventId = this.$route.params.detailId;
            }
            const anecdotalEvent = { ...this.anecdotalEvents.find((row) => row.anecdotalEventId == anecdotalEventId) };
            if (!anecdotalEvent.anecdotalEventId) return null;
            return anecdotalEvent;
        },
        anecdotalEvent() {
            let { anecdotalEventId } = this.$route.params;
            if (this.studentEnrollmentId) {
                anecdotalEventId = this.$route.params.detailId;
            }
            const anecdotalEvent = { ...this.anecdotalEvents.find((row) => row.anecdotalEventId == anecdotalEventId) };
            if (!anecdotalEvent.anecdotalEventId) return null;

            if (!this.user.school.schoolTermId == anecdotalEvent.schoolTermId) {
                const author = this.schoolStaff.filter(({ schoolStaffId, schoolTermId }) => schoolStaffId == anecdotalEvent.authorId && schoolTermId == anecdotalEvent.schoolTermId);
                anecdotalEvent.author = `${author.lastName}, ${author.firstName}`;
            }

            anecdotalEvent.lastUpdated = moment(anecdotalEvent.lastUpdated).format('MMM Do YYYY h:mm a');
            anecdotalEvent.originalAnecdotalEventDate = anecdotalEvent.anecdotalEventDate;
            anecdotalEvent.anecdotalEventDate = moment(anecdotalEvent.anecdotalEventDate).format('MMM Do YYYY');
            anecdotalEvent.anecdotalEventTime = (anecdotalEvent.anecdotalEventTime) ? moment(anecdotalEvent.anecdotalEventTime, 'HH:mm').format('hh:mm a') : null;
            return anecdotalEvent;
        },
        isStudentView() {
            const homeUser = this.$_userMixins_isHomeUser;
            const schoolUser = this.$_userMixins_isSchoolUser;

            if (homeUser) return true;
            if (schoolUser) {
                if (this.forceStudentView) {
                    return true;
                }
                return false;
            }
            return true;
        },
        title() {
            return this.$route.name;
        },
        lastUpdated() {
            if (!this.anecdotalEvent) return '';
            return moment(this.anecdotalEvent.originalAnecdotalEventDate, 'YYYY-MM-DD').fromNow();
        },
        resolvable() {
            if (!this.anecdotalEvent) return false;
            return this.anecdotalEvent.pointValue < 0;
        },
        isInCurrentTerm() {
            if (!this.anecdotalEvent) return false;
            return (this.user.school.schoolTermId == this.anecdotalEvent.schoolTermId);
        },
        canEdit() {
            if (!this.isInCurrentTerm) return false;
            return this.editSchoolAnecdotalEvents
                || this.anecdotalEvent?.authorId == (this.user?.mappedUserId || this.user.userId);
        },
        students() {
            if (!this.anecdotalEvent) return [];
            const studentEnrollmentIds = this.anecdotalEvent.students.map((s) => s.studentEnrollmentId || null).filter((s) => s);
            return this.$_studentMixins_getStudentsByEnrollmentIds(studentEnrollmentIds);
        },
        teachers() {
            const { teachers } = this.$store.state.database;
            if (!this.anecdotalEvent) return [];
            const output = this.anecdotalEvent.teachers.map((t) => {
                const teacher = teachers.find((tc) => tc.studentEnrollmentId == t.studentEnrollmentId);
                return teacher ? { ...teacher } : null;
            }).filter((t) => t);

            return output;
        },
        comments() {
            const v = this;
            return v.anecdotalEventComments.map((c, idx) => {
                const comment = c;
                comment.vueId = `anecdotalEventComments_${c.created}_${idx}_${c.userId}`;
                comment.relativeDate = moment(c.created).fromNow();
                comment.user = v.teachers.find((x) => x.userId == c.userId) || null;
                comment.fromMe = Boolean(c.userId == (v.user?.mappedUserId || v.user.userId));
                return comment;
            });
        },
    },
    watch: {
        anecdotalEvent: {
            handler() {
                this.loadChats(true);
            },
            deep: true,
        },
    },
    mounted() {
        if (!this.anecdotalEvent) {
            this.$store.dispatch(Types.actions.GET_ANECDOTAL_EVENT_BY_ID, { anecdotalEventId: this.$route.params.anecdotalEventId || this.$route.params.detailId });
        }
        this.loadChats();
    },
    methods: {
        resolveAnecdotalEvent(resolve) {
            const { passableAnecdotalEvent, showError } = this;
            const { dispatch } = this.$store;
            const action = resolve ? Types.actions.RESOLVE_ANECDOTAL_EVENT : Types.actions.UNRESOLVE_ANECDOTAL_EVENT;
            dispatch(action, {
                anecdotalEvent: passableAnecdotalEvent,
                callback: (err) => {
                    if (err) return showError(err);
                },
            });
        },
        openPanelForStudent(student) {
            this.$_panelMixins_openPanelForStudent(student);
        },
        saveComment() {
            const v = this;
            if (v.saving) return;
            const anecdotalEventComment = (this.anecdotalEventComment || '').trim();
            if (anecdotalEventComment == '') return v.showError('Please enter a comment before saving');

            v.saving = true;

            const { anecdotalEventId } = this.anecdotalEvent;
            const { schoolId, schoolTermId } = this.user.school;
            const params = { url: { schoolId, schoolTermId, anecdotalEventId }, body: { anecdotalEventComment } };
            network.anecdotals.addAnecdotalsComment(params, (err) => {
                v.saving = false;
                if (err) return v.showError(err);
                this.anecdotalEventComment = '';
                v.loadChats();
            });
        },
        loadChats(firstLoad) {
            const v = this;
            if (!v.anecdotalEvent) return;
            if (v.isStudentView) return;

            v.anecdotalEventComments = [];
            if (firstLoad) v.loadingChats = true;

            const { anecdotalEventId } = this.anecdotalEvent;
            const { schoolId, schoolTermId } = this.user.school;
            const params = { url: { schoolId, schoolTermId, anecdotalEventId } };
            network.anecdotals.commentsForEventId(params, (err, res) => {
                v.loadingChats = false;
                if (err) return v.showError(err);
                v.anecdotalEventComments = res.anecdotalEventComments;
                setTimeout(() => {
                    const chats = document.getElementById('chats');
                    if (!chats) return;
                    chats.scrollTop = chats.scrollHeight;
                }, 250);
            });
        },
    },
};
</script>

<style src="../../css/colorSwatch.css" />

<style scoped>
div#chats {
    height: 280px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.point-container {
    font-weight: 600;
    font-size: 1.3rem;
}

div.kt-widget4__pic {
    max-height: 64px;
}
div.student-list {
    max-height: 220px;
    overflow-x: clip;
    overflow-y: scroll;
}
a.kt-widget__username {
    white-space: nowrap;
}
div.student-container {
    width: 100%;
    max-height: 72px;
    overflow-x: clip;
    overflow-y: scroll;
}
div.student-pic {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.kt-widget__subtitle {
    font-size: 1rem;
}
.kt-widget__subtitle span {
    font-size: 1rem;
}
</style>

<style scoped>
.kt-widget--user-profile-1 span {
  line-height: 0;
}

.kt-widget__username {
  font-size: 14px !important;
}

.kt-widget__media {
  width: 25%;
  max-width: 25%;
}

 textarea {
    width: 100%;
    /* border: 0 !important;
    outline: none !important;
    background-color: transparent !important; */
    color: #a2a5b9;
}
textarea {
    overflow: auto;
    resize: vertical;
}

.kt_chat__tools a i {
    margin-right: 1.1rem;
    color: #cdd1e9;
    font-size: 1.4rem;
}

.kt-chat .kt-chat__input .kt-chat__toolbar {
  margin-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

</style>
