import { isHomeUser } from './userMixins';

const panelMixins = {
    methods: {
        $_panelMixins_openPanelForCourseWork(courseWork) {
            const { $store, $router, $route } = this;

            if ($store.state.quickPanel.open) {
                return $router.push({
                    name: $route.name, // same page
                    params: $route.params, // self params
                });
            }

            let query = {
                panel: 'cw',
                linkingGuid: courseWork.linkingGuid,
                courseWorkDomain: courseWork.courseWorkDomain,
            };

            if (courseWork.courseWorkDomain == 'Local') {
                query = {
                    ...query,
                    ...{ courseWorkId: courseWork.courseWorkId },
                };
            } else {
                query = {
                    ...query,
                    ...{ googleCourseWorkId: courseWork.googleCourseWorkId },
                };
            }

            $router.push({
                name: $route.name, // same page
                params: $route.params, // self params
                query,
            });
        },
        $_panelMixins_openPanelForStudent(student, course = null) {
            if (isHomeUser(this.$store.state.user)) return;
            const { $store, $router, $route } = this;

            if ($store.state.quickPanel.open) {
                return $router.push({
                    name: $route.name, // same page
                    params: $route.params, // self params
                });
            }

            const query = {
                panel: 'student',
                studentEnrollmentId: student.studentEnrollmentId,
            };
            if (course) query.courseSectionId = course.courseSectionId;

            $router.push({
                name: $route.name, // same page
                params: $route.params, // self params
                query,
            });

        },
        $_panelMixins_openCommunicationPanel() {
            const { $store, $router, $route } = this;

            if ($store.state.quickPanel.open) {
                return $router.push({
                    name: $route.name, // same page
                    params: $route.params, // self params
                });
            }

            const query = {
                panel: 'communication',
            };

            $router.push({
                name: $route.name, // same page
                params: $route.params, // self params
                query,
            });
        },
        $_panelMixins_openPanelForAi() {
            const { $store, $router, $route } = this;

            if ($store.state.quickPanel.open) {
                return $router.push({
                    name: $route.name, // same page
                    params: $route.params, // self params
                });
            }

            $router.push({
                name: $route.name, // same page
                params: $route.params, // self params
                query: {
                    panel: 'ai',
                    studentEnrollmentId: $route.params.studentEnrollmentId,
                },
            });
        },
        $_panelMixins_openChartCompareForDashboard() {
            const { $store, $router, $route } = this;

            if ($store.state.quickPanel.open) {
                return $router.push({
                    name: $route.name, // same page
                    params: $route.params, // self params
                });
            }
            $router.push({
                name: $route.name, // same page
                params: $route.params, // self params
                query: {
                    panel: 'dashboard',
                },
            });
        },
    },
};

export {
    panelMixins as default,
};
